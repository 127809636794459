import React from 'react';

const ProjectOverview = ({title}) => {



  return (
    <div className="project-tile">
      <h1>{title}</h1>

    </div>
  )
}

export default ProjectOverview;